<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back to projects
    </v-btn>
    <v-row class="mt-3 project-header-sec">
      <v-col cols="7">
        <div>
          <h1 class="project-header">
            {{ projectDetails.name }}
          </h1>
          <div class="project-progress-header">
            <p class="project-progress-title">
              <v-progress-linear
                class="progress-bar-style"
                color="#71DE9D"
                height="20"
                dark
                rounded
                :value="projectDetails.project_progress"
              >
                <template>
                  <strong
                    v-if="projectDetails.project_progress !== null"
                    class="percentage-style"
                  >{{ Math.ceil(projectDetails.project_progress) }}%</strong>
                  <strong v-else>0%</strong>
                </template>
              </v-progress-linear>
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="3">
        <team-members />
      </v-col>
      <v-col cols="2">
        <p>End Date</p>
        <h2>{{ projectDetails.end_date | convertToLocal }}</h2>
      </v-col>
    </v-row>
    <div class="d-flex" />
    <v-tabs
      v-model="projectTabs"
      class="tabs-project-details"
      @change="updateActiveTab"
    >
      <v-tab>
        <v-icon class="mr-2">
          mdi-account-multiple-outline
        </v-icon>Members
      </v-tab>
      <v-tab v-if="isJobModuleExist">
        <v-icon class="mr-2">
          mdi-chart-box-outline
        </v-icon>Jobs
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-domain
        </v-icon> Companies
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-file-document-multiple-outline
        </v-icon> Budgets
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-file-sign
        </v-icon> Main Contracts
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-account-hard-hat-outline
        </v-icon> Contracts
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-file-alert-outline
        </v-icon> Project Issues
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-chart-box-multiple-outline
        </v-icon> Performance Tracking
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="projectTabs">
      <v-tab-item>
        <project-members />
      </v-tab-item>
      <v-tab-item v-if="isJobSchedulesExist">
        <project-scheduling-chart />
      </v-tab-item>
      <v-tab-item>
        <project-companies />
      </v-tab-item>
      <v-tab-item>
        <budgets-list />
      </v-tab-item>
      <v-tab-item>
        <main-contracts-list />
      </v-tab-item>
      <v-tab-item>
        <contracts-list />
      </v-tab-item>
      <v-tab-item>
        <project-issues-list />
      </v-tab-item>
      <v-tab-item>
        <performance-tracking-items
          @show-budgets="getAllBudgets"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import BudgetsList from '././budgets/BudgetsList.vue';
import ProjectCompanies from './companies/ProjectCompanies.vue';
import ProjectIssuesList from './issues/ProjectIssuesList.vue';
import ContractsList from './contracts/ContractsList.vue';
import MainContractsList from './maincontracts/MainContractsList.vue';
import PerformanceTrackingItems from './performancetracking/PerformanceTrackingItems';
import ProjectMembers from './members/ProjectMembers.vue';
import ProjectSchedulingChart from './scheduling/ProjectSchedulingChart.vue';
import TeamMembers from './components/TeamMembers.vue';
import moment from 'moment';
import ModuleHelper from 'src/helpers/module-helper';
import global from 'src/mixins/global';
import Constants from 'src/constants';
  export default {
    name: 'ProjectSecondNavDrawer',
    components: {
      'budgets-list': BudgetsList,
      'project-companies': ProjectCompanies,
      'project-issues-list': ProjectIssuesList,
      'contracts-list': ContractsList,
      'main-contracts-list': MainContractsList,
      'performance-tracking-items': PerformanceTrackingItems,
      'project-members': ProjectMembers,
      'project-scheduling-chart': ProjectSchedulingChart,
      'team-members': TeamMembers,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showBudgetsList: false,
      showProjectCompanies: false,
      showProjectIssues: false,
      showContractsList: false,
      showMainContractsList: false,
      showPerformanceTrackingItems: false,
      showProjectMembers: true,
      showScheduleChart: false,
      projectTabs: null,
      helper: new ModuleHelper(),
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      projectSecondNavDrawerTab () {
        return this.$store.getters['projects/getProjectSecondNavDrawerTab'];
      },
      isPTModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_PERFORMANCE_TRACKING));
      },
      isBudgetModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_BUDGETS));
      },
      isContractModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_CONTRACTS));
      },
      isIssueModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_ISSUES));
      },
      isMainContractsModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_MAIN_CONTRACTS));
      },
      isScheduleModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_SCHEDULE));
      },
      isCompaniesModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_COMPANIES));
      },
      isMembersModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_MEMBERS));
      },
      isJobModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS));
      },
      isJobSchedulesExist () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS, Constants.SUB_MODULE_JOBS_SCHEDULE));
      },
    },
    created () {
      const storedIndex = localStorage.getItem('selectedTabIndex');
    if (storedIndex !== null) {
      this.projectTabs = parseInt(storedIndex, 10); // Set the tab index if it exists
    }
    },
    async mounted () {
      this.selectedItem = 0;
      this.checkForTab();
      await this.getProjectDetails();
    },
    methods: {
      checkForTab () {
        if (this.projectTabs === null) {
          this.getProjectMembers();
          this.selectedItem = 0;
        } else if (this.projectTabs === 0) {
          this.getProjectMembers();
          this.selectedItem = 0;
        } else if (this.projectTabs === 1) {
          this.getProjectSchedulingChart();
          this.selectedItem = 1;
        } else if (this.projectTabs === 2) {
          this.getProjectCompanies();
          this.selectedItem = 2;
        } else if (this.projectTabs === 3) {
          this.getAllBudgets();
          this.selectedItem = 3;
        } else if (this.projectTabs === 4) {
          this.getProjectMainContracts();
          this.selectedItem = 4;
        } else if (this.projectTabs === 5) {
          this.getProjectContracts();
          this.selectedItem = 5;
        } else if (this.projectTabs === 6) {
          this.getProjectIssues();
          this.selectedItem = 6;
        } else if (this.projectTabs === 7) {
          this.getPerformanceTrackingItems();
          this.selectedItem = 7;
        }
      },
      async getProjectDetails () {
        await this.$store.dispatch('projects/fetchProjectDetails', {
          projectId: this.$route.params.projectId,
        });
      },
      back () {
        this.$router.push({ name: 'Projects' });
      },
      getAllBudgets () {
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showBudgetsList = true;
        this.selectedItem = 3;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectCompanies () {
        this.showBudgetsList = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showProjectCompanies = true;
        this.selectedItem = 2;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectIssues () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showProjectIssues = true;
        this.selectedItem = 6;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectContracts () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showContractsList = true;
        this.selectedItem = 5;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectMainContracts () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showMainContractsList = true;
        this.selectedItem = 4;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getPerformanceTrackingItems () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showPerformanceTrackingItems = true;
        this.selectedItem = 7;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectMembers () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showScheduleChart = false;
        this.showProjectMembers = true;
        this.selectedItem = 1;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectSchedulingChart () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = true;
        this.selectedItem = 0;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      updateActiveTab () {
        localStorage.setItem('selectedTabIndex', this.projectTabs);
    },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.drawer-style {
  font-size:25px;
  color:#4f4f37;
  font-weight:bold;
}
.premium-style {
  font-size:25px;
  color:#006883;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.project-header {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 32px;
}
.project-progress-header{
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
}
.project-progress-title{
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
  display: flex;
  gap: 20px;
}
.progress-bar-style{
  width: 40%;
  margin-top: 6px;
}
.percentage-style{
  font-size: 15px;
  color: #000;
}
.project-name {
  color: #FF3700;
  text-transform: capitalize;
}
.tabs-project-details{
  margin-left: 40px;
}
.project-header-sec{
  padding-bottom: 50px;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
@media (max-width: 767px) {
  .project-header {
    margin-left: 20px;
  }
  .project-progress-title{
    display: block;
  }
  .progress-bar-style{
    width: 100%;
    margin-top: 8px;
  }
  .project-progress-header{
    margin-left: 20px;
  }
}
</style>
