<template>
  <v-dialog
    v-model="showModal"
    width="700"
    persistent
    class="create-job-sidebar"
    right
  >
    <v-card
      color="#fff"
      class="pa-3"
    >
      <v-icon
        class="close-btn"
        @click="showModal = false"
      >
        mdi-close
      </v-icon>
      <h2 class="title-style pt-3">
        <v-text-field v-model="jobTitle" />
      </h2>
      <p class="sub-heading-creation">
        {{ description }}
      </p>
      <v-tabs v-model="tab">
        <v-tab>Details</v-tab>
        <v-tab>Comments<span class="comment_notification">{{ jobComments.length }}</span></v-tab>
        <v-tab>Sub Jobs</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form ref="form">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between job-dates-progress">
                    <div class="d-flex">
                      <div class="mr-3 border-right">
                        <p class="mb-1">
                          Planned Start Date
                        </p>
                        <p class="mb-1">
                          <b>{{ plannedStartDate }}</b>
                        </p>
                      </div>
                      <div class="border-right">
                        <p class="mb-1">
                          Planned End Date
                        </p>
                        <p class="mb-1">
                          <b>{{ plannedEndDate }}</b>
                        </p>
                      </div>
                    </div>
                    <div class="job-progress">
                      <p class="mb-1">
                        Progress
                      </p>
                      <div class="d-flex">
                        <v-slider
                          v-model="progress"
                          thumb-label="always"
                          :thumb-size="24"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    outlined
                    dense
                    label="Description"
                    class="field-style"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="jobType"
                    :items="jobTypeList"
                    disabled
                    label="Select Job Type *"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startDialog"
                    v-model="startDateDialog"
                    :return-value.sync="startDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="plannedStartDate"
                        label="Planned Start Date*"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="plannedStartDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.startDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="startDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endDialog"
                    v-model="endDateDialog"
                    :return-value.sync="endDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="plannedEndDate"
                        label="Planned End Date*"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="plannedEndDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.endDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="endDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startActualDialog"
                    v-model="startActualDateDialog"
                    :return-value.sync="astartDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actualStartDate"
                        label="Actual Start Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="actualStartDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.startActualDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="startActualDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endActualDialog"
                    v-model="endActualDateDialog"
                    :return-value.sync="aendDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actualEndDate"
                        label="Actual End Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="actualEndDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.endActualDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="endActualDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="parentJobId"
                    item-text="name"
                    item-value="id"
                    :items="filteredJobList"
                    :disabled="isParentJobExist"
                    label="Select Parent Job"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    outlined
                    dense
                    class="field-style"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="companyId"
                    item-text="company.name"
                    item-value="company.id"
                    :items="projectCompanies"
                    label="Assign a Company"
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="memberId"
                    :item-text="memberName"
                    item-value="app_user_organization_id"
                    :items="CompanyUsers"
                    label="Assign a Member"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="status"
                    :items="statusList"
                    label="Select a Status"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="priority"
                    :items="priorityList"
                    label="Select Priority"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  class="mt-2"
                  color="#000"
                  @click="updateJob()"
                >
                  Update
                </v-btn>
                <v-btn
                  class="mt-2"
                  color="#aeaeae"
                  @click="showModal = false"
                >
                  cancel
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item class="comment-section">
          <p v-if="jobComments.length == 0">
            There is no comment to display.
          </p>
          <div v-else>
            <!-- {{ jobComments }} -->
            <div
              v-for="jobcomment in jobComments"
              :key="jobcomment.id"
            >
              <div
                class="comment-section-inner"
                :class="{ 'text-right': checkUser(jobcomment.sent_user.external_id) }"
              >
                <div v-if="jobcomment.document_file">
                  <img
                    :src="getImageUrl(jobcomment.document_file.file_path)"
                    :alt="jobcomment.document_file.original_file_name"
                    class="comment-image"
                  >
                </div>
                <div class="message_bubble_left text-left">
                  <p class="sent_time">
                    {{ jobcomment.sent_user.first_name }} {{ jobcomment.sent_user.last_name }}
                  </p>
                  <h4 :class="{ 'text-left': checkUser(jobcomment.sent_user.external_id) }">
                    {{ jobcomment.comment }}
                  </h4>
                </div>
                <p class="sent_time">
                  {{ jobcomment.created_at | fromNow }}
                </p>
              </div>
            </div>
          </div>
          <v-textarea
            v-model="comment"
            outlined
            class="mt-3"
            @keyup.enter="addComment()"
          >
            <template #prepend-inner>
              <div :style="imageUrl !== '' ? 'width:100px' : 'width:0px'">
                <v-icon
                  v-if="imageUrl !== ''"
                  class="clear-image-icon"
                  @click="clearImage"
                >
                  mdi-close-circle-outline
                </v-icon>
                <v-img
                  style="margin: auto 0;border-radius: 5px"
                  :src="imageUrl"
                />
              </div>
            </template>
          </v-textarea>
          <v-file-input
            v-model="uploadImages"
            hide-input
            truncate-length="15"
            class="upload_btn"
            accept=".jpg,.jpeg,.png,.pdf"
            @change="onFileChange"
          />
          {{ uploadImages.document_file }}
          <v-btn
            color="#000"
            @click="addComment"
          >
            Send
          </v-btn>
        </v-tab-item>
        <v-tab-item>
          <div
            v-for="subjob in subJobs"
            :key="subjob.id"
            class="subjob"
          >
            <h4>{{ subjob.name }}</h4>
            <div>
              <!-- <v-btn
                icon
                color="#000"
                class="btn-action"
                @click="openSubJobDetails(subjob.id)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn> -->
              <v-btn
                icon
                color="#000"
                class="btn-action"
                @click="showDeleteDialog(subjob.external_id)"
              >
                <v-icon color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- <edit-job /> -->
    <delete-job
      v-if="showDelete"
      :title="'Delete Job ?'"
      :message="'Action will permanently remove the job from this project, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </v-dialog>
</template>
<script>
import moment from 'moment';
import Constants from 'src/constants';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
export default {
  components: {
    'delete-job': DeleteDialog,
  },
  filters: {
    fromNow (value) {
      return moment(value).fromNow();
    },
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      jobTitle: '',
      description: '',
      startDate: '',
      endDate: '',
      astartDate: '',
      aendDate: '',
      plannedStartDate: '',
      plannedEndDate: '',
      actualStartDate: '',
      actualEndDate: '',
      progress: '',
      parentJobId: '',
      amount: '',
      companyId: null,
      contractorId: '',
      memberId: null,
      status: '',
      priority: '',
      jobType: '',
      jobTypeList: ['milestone', 'task', 'activity'],
      priorityList: ['low', 'medium', 'high', 'critical'],
      statusList: ['pending', 'completed', 'suspended', 'delayed'],
      startDateDialog: false,
      endDateDialog: false,
      minStartDate: moment().format('YYYY-MM-DD'),
      date: '',
      jobList: [],
      companyList: [],
      contractorList: [],
      memberList: [],
      tab: null,
      subJobs: [],
      showDelete: false,
      startActualDateDialog: false,
      endActualDateDialog: false,
      deletingJobId: '',
      comment: '',
      uploadImages: {},
      imageUrl: '',
    };
  },
  computed: {
    jobDetails () {
      return this.$store.getters['jobs/getJobDetails'];
    },
    isParentJobExist () {
      if (this.jobDetails.parent_job_id === null) {
        return false;
      }
      return true;
    },
    JobList () {
      return this.$store.getters['jobs/getJobList'];
    },
    filteredJobList () {
      if (this.jobDetails && this.jobDetails.name && this.jobDetails.sub_jobs?.length) {
        return this.JobList.filter(item =>
          item.name !== this.jobDetails.name &&
          !this.jobDetails.sub_jobs.some(subJob => subJob.name === item.name),
        );
      }
      return this.JobList.filter(item => item.name !== this.jobDetails.name);
    },

    CompanyUsers () {
      return this.$store.getters['companies/getCompanyUsers'];
    },
    jobComments () {
      return this.$store.getters['jobs/getJobComments'];
    },
    projectCompanies () {
      return this.$store.getters['projects/getProjectCompanies'];
    },
    currentUser () {
      return this.$store.getters.getUser;
    },

  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
    companyId (newVal, oldVal) {
      this.getCompanyUsers(newVal);
    },
  },
  async mounted () {
    this.loading = true;
    await this.getJobDetails();
    await this.getJobList();
    await this.getProjectCompanies();
    await this.getJobComments({ itemsPerPage: 100, pageStart: 0 });
    this.loading = false;
  },
  methods: {
    getImageUrl (filePath) {
      const filep = `/${filePath}`;
      const baseUrl = process.env.VUE_APP_STORAGE_API_URL;
      return `${baseUrl}${filep}`;
    },
    clearImage () {
      this.uploadImages = [];
      this.imageUrl = '';
    },
    createImage (file) {
      const reader = new FileReader();

      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange (file) {
      this.uploadImages = file;
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    async addComment () {
      const formData = new FormData();
      if (this.uploadImages instanceof File) {
        formData.append('document_file', this.uploadImages);
      } else {
        formData.append('document_file', '');
      }
      formData.append('comment', this.comment);
      formData.append('jobId', this.jobId);
      await this.$store.dispatch('jobs/addJobComment', { formData }).then(response => {
        this.comment = '';
        this.clearImage();
        this.getJobComments({ itemsPerPage: 100, pageStart: 0 });
      });
    },
    async getJobComments ({ itemsPerPage, pageStart }) {
      await this.$store.dispatch('jobs/fetchJobComments', {
        jobId: this.jobId,
        params: {
          limit: itemsPerPage,
          startPage: pageStart,
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    showDeleteDialog (jobid) {
      this.deletingJobId = jobid;
      this.showDelete = true;
    },
    deleteConfirmed () {
      this.loading = true;
      this.showDelete = false;
      this.$store.dispatch('jobs/deleteJob', {
        jobId: this.deletingJobId,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Job Deleted successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.loading = false;
        this.getJobDetails();
      }).catch(() => {
        this.loading = false;
      });
    },
    openSubJobDetails (subjobid) {
      this.showModal = true;
    },
    memberName (e) {
      return `${e.first_name} ${e.last_name}`;
    },
    async getProjectCompanies () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchProjectCompanies', {
        projectId: this.$route.params.projectId,
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getCompanyUsers (companyid) {
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('companies/fetchCompanyUsers', { companyId: companyid }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getJobList () {
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('jobs/fetchJobsByProject', { projectid: this.$route.params.projectId }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getJobDetails () {
      this.loading = true;
      await this.$store.dispatch('jobs/JobDetails', {
        jobId: this.jobId,
      }).then(response => {
        this.jobTitle = this.jobDetails.name;
        this.plannedStartDate = this.jobDetails.planned_start_date;
        this.plannedEndDate = this.jobDetails.planned_end_date;
        this.progress = this.jobDetails.progress;
        this.status = this.jobDetails.status;
        this.description = this.jobDetails.description;
        this.jobType = this.jobDetails.type;
        this.parentJobId = this.jobDetails.parent_job_id;
        this.amount = this.jobDetails.amount;
        this.status = this.jobDetails.status;
        this.priority = this.jobDetails.priority;
        this.memberId = this.jobDetails.assigned_to_id;
        this.companyId = this.jobDetails.company_id;
        this.subJobs = this.jobDetails.sub_jobs;
        this.actualStartDate = this.jobDetails.actual_start_date;
        this.actualEndDate = this.jobDetails.actual_end_date;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    async updateJob () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      await this.$store.dispatch('jobs/updateJob', {
        name: this.jobTitle,
        description: this.description,
        planned_start_date: this.plannedStartDate,
        planned_end_date: this.plannedEndDate,
        actual_start_date: this.actualStartDate,
        actual_end_date: this.actualEndDate,
        status: this.status,
        type: this.jobType,
        priority: this.priority,
        assigned_to_id: this.memberId ? this.memberId : this.companyId,
        assigned_to_type: this.memberId === null ? 'company' : 'app-user',
        amount: Number(this.amount),
        parent_job_id: this.parentJobId,
        template_id: this.$route.params.projectId,
        template_type: 'project',
        jobId: this.jobId,
        progress: this.progress.toString(),
      }).then(response => {
        this.$emit('success');
        this.$store.dispatch('alert/onAlert', {
          message: 'New Job has been created successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.loading = false;
        this.$emit('success');
        this.showModal = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    checkUser (id) {
      if (this.currentUser.user.external_id === id) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.icon-style {
  color: #37474F;
  font-size: 32px !important;
}

.field-style {
  margin-top: -10px;
}

.create-job-sidebar {
  position: absolute !important;
  right: 0;
  top: 0;
  max-width: 650px;
}

.sub-heading-creation {
  margin-bottom: 6px !important;
  color: #aaa !important;
  margin-left: 21px;
  margin-right: 21px;
}

.job-progress {
  width: 200px;
}

.percentage-style {
  color: #000;
}

.border-right {
  border-right: 1px solid #ccc;
  padding: 5px;
}

.job-dates-progress {
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.progress-bar-style {
  cursor: pointer;
}

.v-select-list {
  text-transform: capitalize;
}

.upload_btn {
  margin-top: -80px;
  float: right;
  margin-right: 7px;
  background: none !important;
  color: #000 !important;
}

.comment_notification {
  background: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-top: -10px;
  padding: 5px;
}

.subjob {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.btn-action {
  background: #fff !important;
}

.comment-section {
  padding: 15px;
}

.message_bubble_left {
  display: inline-block;
  padding: 12px 18px;
  border-radius: 18px;
  max-width: 70%;
  word-wrap: break-word;
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.4;
  background-color: #e0e0e0;
  color: #333;
}

.width-message {
  width: fit-content;
  margin-bottom: 10px;
}

.sent_time {
  font-size: 9px;
}

.clear-image-icon {
  float: right;
}

.comment-image {
  width: 225px;
  border-radius: 5px;
  border: 5px solid #ddd;
}

.comment-section-inner {
  overflow-y: scroll;
}

.close-btn {
  float: right;
}
</style>
